<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <h3>Articles ({{ posts.length }})</h3>
    <div class="article" v-for="post in posts" :key="post.id">
      <div class="title">
        <b>#{{ post.id }}</b> {{ post.title }}
      </div>
      <div class="description">{{ post.body }}</div>
    </div>
    <div class="error" v-if="error">{{ error }}</div>
    <div class="loading" v-if="loading">Loading...</div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      posts: [],
      error: null,
      loading: true,
    };
  },
  mounted() {
    fetch("https://jsonplaceholder.typicode.com/posts?_limit=30")
      .then((response) => response.json())
      .then((json) => {
        this.posts = json;
        this.loading = false;
      })
      .catch(() => {
        this.error = "Articles getting error";
        this.loading = false;
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.article {
  text-align: left;
  max-width: 500px;
  width: 100%;
  margin: 20px auto 0 auto;
}

.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.error,
.loading {
  font-size: 26px;
  margin-top: 40px;
  color: indianred;
}

.loading {
  color: mediumseagreen;
}
</style>
